<template>
  <div class="main">
    <div class="row mx-4 my-4">
      <EmployeeCard></EmployeeCard>
      <div class="col-xl-9 col-lg-8 ps-4 userSettings">
        <ProfileProgress></ProfileProgress>
        <div class="mt-4 settingsBody rounded">
          <div class="pt-3 pb-0 ps-4">
            <strong>Datos Básicos</strong>
          </div>
          <hr />
          <div>
            <form class="settingForm" @submit.prevent="sendBasic">
              <div class="mb-3">
                <label for="name" class="form-label">Nombres y Apellidos</label>
                <input
                  type="text"
                  class="form-control"
                  name="name"
                  minlength="1"
                  :maxlength="maxName"
                  v-model="name"
                  required
                />
              </div>
              <div class="mb-3">
                <label for="IDType" class="form-label">Tipo de Documento</label>
                <select
                  class="form-select text-capitalize"
                  name="IDType"
                  v-model="IDType"
                  required
                >
                  <option value="CC">Cédula de Ciudadanía</option>
                  <option value="CE">Cédula de Extranjería</option>
                  <option value="PEP">Permiso Especial de Permanencia</option>
                </select>
              </div>
              <div class="mb-3">
                <label for="ID" class="form-label">Número de Documento</label>
                <div class="input-group">
                  <span class="input-group-text">{{ IDType }}</span>
                  <input
                    type="number"
                    class="form-control"
                    name="ID"
                    :min="minID"
                    :max="maxID"
                    v-model="ID"
                    required
                  />
                </div>
              </div>
              <div class="mb-3">
                <label for="gender" class="form-label">Género</label>
                <select
                  class="form-select text-capitalize"
                  name="gender"
                  required
                  v-model="gender"
                >
                  <option
                    v-for="gender in genderList"
                    v-bind:value="gender"
                    :key="gender"
                    >{{ gender.toLowerCase().replace("_", " ") }}</option
                  >
                </select>
              </div>
              <div class="mb-3">
                <label for="race" class="form-label">Grupo Étnico</label>
                <select
                  class="form-select text-capitalize"
                  name="race"
                  required
                  v-model="race"
                >
                  <option
                    v-for="race in raceList"
                    v-bind:value="race"
                    :key="race"
                    >{{ race.toLowerCase().replace("_", " ") }}</option
                  >
                </select>
              </div>
              <div class="mb-3">
                <label for="bloodType" class="form-label">Tipo de Sangre</label>
                <select
                  class="form-select text-capitalize"
                  name="bloodType"
                  required
                  v-model="bloodType"
                >
                  <option
                    v-for="bloodType in bloodTypeList"
                    v-bind:value="bloodType"
                    :key="bloodType"
                    >{{
                      bloodType
                        .replace("_", " ")
                        .replace("POSITIVO", "+")
                        .replace("NEGATIVO", "-")
                    }}</option
                  >
                </select>
              </div>
              <div class="mb-3">
                <label for="maritalStatus" class="form-label"
                  >Estado Civil</label
                >
                <select
                  class="form-select text-capitalize"
                  name="maritalStatus"
                  required
                  v-model="maritalStatus"
                >
                  <option
                    v-for="maritalStatus in maritalStatusList"
                    v-bind:value="maritalStatus"
                    :key="maritalStatus"
                    >{{ maritalStatus.toLowerCase().replace("_", " ") }}</option
                  >
                </select>
              </div>
              <div class="mb-3">
                <label for="dateOfBirth" class="form-label"
                  >Fecha de Nacimiento</label
                >
                <input
                  type="date"
                  class="form-control"
                  name="dateOfBirth"
                  v-model="dateOfBirth"
                  :max="maxDateOfBirth"
                  required
                />
              </div>

              <div class="mb-3 form-check form-switch">
                <label for="responsable" class="form-label"
                  >Responsable del SG SST</label
                >
                <input
                  type="checkbox"
                  class="form-check-input"
                  name="responsable"
                  v-model="userExists"
                  :disabled="userExists || !email"
                  @click="newUser"
                />
              </div>
              <div v-if="!email" class="alert alert-danger" role="alert">
                El usuario debe tener un correo electrónico registrado para ser
                asignado como responsable.
              </div>

              <button type="submit mb-3" class="btn btn-primary shadow-primary">
                Actualizar
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
    <Legal></Legal>
  </div>
</template>

<script>
import Legal from "@/components/layout/Legal";
import EmployeeCard from "@/views/employee/EmployeeCard";
import ProfileProgress from "@/views/employee/ProfileProgress";

import Storage from "@/modules/storage";
import Swal from "sweetalert2";

export default {
  components: { Legal, EmployeeCard, ProfileProgress },
  name: "Profile Edit Basic",
  data() {
    return {
      mainId: "",
      branch: "",
      name: "",
      IDType: "",
      ID: "",
      gender: "",
      race: "",
      bloodType: "",
      maritalStatus: "",
      dateOfBirth: "",
      userExists: true,
      email: null,
      genderList: [],
      raceList: [],
      bloodTypeList: [],
      maritalStatusList: [],
      maxName: 1,
      minID: 0,
      maxID: 1
    };
  },
  methods: {
    newUser() {
      Swal.fire({
        icon: "warning",
        title: "Registrando responsable",
        html:
          "Por favor espere mientras se crea el usuario</b> " +
          '<div class="progress mt-3"><div class="progress-bar" id="progressDataForm" role="progressbar" style="width: 0%;" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"></div></div>',
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        stopKeydownPropagation: true
      });

      const data = {
        name: this.name,
        rol: "responsable",
        IDType: this.IDType,
        ID: this.ID,
        email: this.email,
        branch: this.branch,
        employee: this.mainId
      };
      const store = new Storage();
      store
        .insertData("optima", "user", [data])
        .then(() => {
          this.endSubmit(true);
        })
        .catch(error => {
          console.log(error);
          this.endSubmit(false);
        });
    },
    endSubmit(status) {
      if (status) {
        Swal.fire({
          icon: "success",
          title: "Usuario asignado como responsable",
          text: "Todos los datos han sido sincronizados"
        }).then(() => {
          this.$router.go(0);
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Error al asignar al usuario responsable",
          text:
            "Esto puede deberse a que ya hay un usuario registrado con el correo electrónico del empleado"
        }).then(() => {
          this.$router.go(0);
        });
      }
    },
    sendBasic() {
      Swal.fire({
        icon: "warning",
        title: "Actualizando información",
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        stopKeydownPropagation: true,
        timerProgressBar: true,
        didOpen: () => {
          Swal.showLoading();
          setInterval(() => {
            const content = Swal.getContent();
            if (content) {
              const b = content.querySelector("b");
              if (b) {
                b.textContent = Swal.getTimerLeft();
              }
            }
          }, 100);
        }
      });
      const store = new Storage();
      let dateOfBirth = this.dateOfBirth;
      const year = dateOfBirth.split("-")[0];
      let month = dateOfBirth.split("-")[1] - 1;
      if (month < 10) {
        month = "0" + month.toString();
      }
      let day = dateOfBirth.split("-")[2];
      day++;
      if (day < 10) {
        day = "0" + day.toString();
      }
      dateOfBirth = new Date(Date.UTC(year, month, day));
      dateOfBirth = dateOfBirth.getTime();

      const data = {
        _id: this.$route.params._id,
        name: this.name,
        IDType: this.IDType,
        ID: this.ID,
        gender: this.gender,
        race: this.race,
        bloodType: this.bloodType,
        maritalStatus: this.maritalStatus,
        dateOfBirth: dateOfBirth
      };
      store
        .updateData("employee", [data], true, "optima")
        .then(() => {
          Swal.fire({
            icon: "success",
            title: "Datos Actualizados",
            text: "Todos los datos han sido sincronizados"
          }).then(() => {
            this.$router.go();
          });
        })
        .catch(error => {
          console.log(error);
          Swal.fire({
            icon: "error",
            title: "Error al actualizar los datos",
            text:
              "Los datos han sido guardados en su computador o celular, una vez se restablesca la conexión, los datos se sincronizarán con el servidor"
          }).then(result => {
            this.$router.go();
          });
        });
    }
  },
  computed: {
    maxDateOfBirth: function() {
      const d = new Date();
      const year = d.getFullYear() - 10;
      let month = d.getMonth() + 1;
      if (month < 10) {
        month = "0" + month.toString();
      }
      let day = d.getDate();
      if (day < 10) {
        day = "0" + day.toString();
      }
      return year + "-" + month + "-" + day;
    }
  },
  beforeCreate() {
    const store = new Storage();

    store.getData("employee", this.$route.params._id).then(employeeData => {
      store.getData("branch", employeeData.branch).then(branchData => {
        store.getData("city", branchData.city).then(cityData => {
          localStorage.setItem(
            "currentLocation",
            "Empleados / Perfiles / " +
              cityData.name +
              " / " +
              employeeData.name +
              " / Datos Básicos"
          );
        });
      });
    });
  },
  mounted() {
    const store = new Storage();
    store.getData("employee", this.$route.params._id).then(employee => {
      this.mainId = employee._id;
      this.branch = employee.branch;
      this.name = employee.name;
      this.IDType = employee.IDType;
      this.ID = employee.ID;
      this.gender = employee.gender;
      this.race = employee.race;
      this.bloodType = employee.bloodType;
      this.maritalStatus = employee.maritalStatus;
      this.dateOfBirth = employee.dateOfBirth;
      this.email = employee.email;

      store.getData("user").then(users => {
        let userFlag = false;
        users.forEach(user => {
          if (user.employee == employee._id) {
            userFlag = true;
          }
        });
        this.userExists = userFlag;
      });

      if (this.dateOfBirth !== null) {
        const d = new Date(this.dateOfBirth);
        const year = d.getFullYear();
        let month = d.getMonth() + 1;
        if (month < 10) {
          month = "0" + month.toString();
        }
        let day = d.getDate();
        if (day < 10) {
          day = "0" + day.toString();
        }
        this.dateOfBirth = year + "-" + month + "-" + day;
      }
    });
    store.getData("schema").then(schemaList => {
      schemaList.forEach(schema => {
        if (schema.store === "employee") {
          this.genderList = schema.data.gender.enum;
          this.raceList = schema.data.race.enum;
          this.bloodTypeList = schema.data.bloodType.enum;
          this.maritalStatusList = schema.data.maritalStatus.enum;
          this.maxName = schema.data.name.max;
          this.minID = schema.data.ID.min;
          this.maxID = schema.data.ID.max;
        }
      });
    });
  }
};
</script>

<style lang="css" scoped>
.settingsBody {
  background-color: var(--secondary-bg);
}
.settingForm {
  margin: auto;
  padding-bottom: 20px;
  width: 50%;
  min-width: 280px;
}
@media only screen and (max-width: 990px) {
  .userSettings {
    padding: 0 !important;
  }
  .userSettings {
    margin-block: 30px;
  }
}
</style>
